var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":_vm.loadingText,"element-loading-spinner":"el-icon-loading","element-loading-background":_vm.loadingColor}},[_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{attrs:{"xl":4,"lg":4,"md":4}},[_c('div',{staticClass:"filter-card sidebar-filters"},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[_c('el-collapse',{model:{value:(_vm.activeCol),callback:function ($$v) {_vm.activeCol=$$v},expression:"activeCol"}},[_c('el-collapse-item',{attrs:{"name":"filters"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"collopse-header"},[_vm._v("Saved Filters")])]),_c('div',{staticClass:"filters-body"},[_c('ul',[_c('li',{class:_vm.isActive == 'all' ? 'active-filter' : ''},[(
                        !_vm.isApplicationUserSide ||
                        (_vm.isActive &&
                          _vm.isActive == 'all' &&
                          _vm.isApplicationUserSide)
                      )?_c('el-link',{staticStyle:{"margin-left":"12px !important","margin-top":"5px"},attrs:{"underline":false,"type":_vm.isActive == 'all' ? 'danger' : ''},on:{"click":function($event){return _vm.updateFilter('all')}}},[_vm._v(" All ")]):_vm._e(),(_vm.isActive == 'all')?_c('div',{staticClass:"float-right mr-1",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s(_vm.total)+" ")]):_vm._e()],1),_vm._l((_vm.entityAllFilters),function(filter){return _c('li',{key:filter._id,class:_vm.isActive == filter._id ? 'active-filter' : ''},[_c('el-link',{staticStyle:{"margin-left":"12px !important","margin-top":"5px"},attrs:{"underline":false,"type":_vm.isActive == filter._id ? 'danger' : ''},on:{"click":function($event){return _vm.updateFilter(filter._id)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(filter.filter_name,17, "..."))+" ")]),(_vm.isActive == filter._id)?_c('div',{staticClass:"float-right mr-1",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s(_vm.total)+" ")]):_vm._e()],1)})],2)])],2)],1),(_vm.entityFiltersData && _vm.entityFiltersData.quick_filters)?_c('div',[_c('quick-entity-filters',{attrs:{"filterFields":_vm.entityFiltersData && _vm.entityFiltersData.quick_filters
                  ? _vm.entityFiltersData.quick_filters
                  : [],"allFields":_vm.selectedEntityFields,"selectedFileds":_vm.selectedFileds,"showVertically":true},on:{"quick-filter-updated":_vm.quickFilterUpdated}})],1):_vm._e()],1)],1),_c('div',{staticClass:"sidebar-filters-mobile"},[_c('el-collapse',{model:{value:(_vm.activeCol),callback:function ($$v) {_vm.activeCol=$$v},expression:"activeCol"}},[_c('el-collapse-item',{attrs:{"name":"filters"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"collopse-header"},[_vm._v("Saved Filters")])]),_c('div',{staticClass:"filters-body"},[_c('ul',[_c('li',{class:_vm.isActive == 'all' ? 'active-filter' : ''},[_c('el-link',{staticStyle:{"margin-left":"12px !important","margin-top":"5px"},attrs:{"underline":false,"type":_vm.isActive == 'all' ? 'danger' : ''},on:{"click":function($event){return _vm.updateFilter('all')}}},[_vm._v(" All ")])],1),_vm._l((_vm.entityAllFilters),function(filter){return _c('li',{key:filter._id,class:_vm.isActive == filter._id ? 'active-filter' : ''},[_c('el-link',{staticStyle:{"margin-left":"12px !important","margin-top":"5px"},attrs:{"underline":false,"type":_vm.isActive == filter._id ? 'danger' : ''},on:{"click":function($event){return _vm.updateFilter(filter._id)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(filter.filter_name,17, "..."))+" ")])],1)})],2)]),(_vm.entityFiltersData && _vm.entityFiltersData.quick_filters)?_c('div',[_c('quick-entity-filters',{attrs:{"filterFields":_vm.entityFiltersData && _vm.entityFiltersData.quick_filters
                    ? _vm.entityFiltersData.quick_filters
                    : [],"allFields":_vm.selectedEntityFields,"selectedFileds":_vm.selectedFileds},on:{"quick-filter-updated":_vm.quickFilterUpdated}})],1):_vm._e()],2)],1)],1)]),_c('el-col',{attrs:{"xl":20,"lg":20,"md":20,"sm":24,"xs":24}},[_c('div',{staticClass:"filter-card",staticStyle:{"overflow-y":"hidden"}},[_c('div',{staticClass:"float-right",staticStyle:{"margin-right":"20px"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Previous page","placement":"top-start"}},[_c('el-link',{attrs:{"icon":"el-icon-arrow-left","disabled":_vm.page == 1,"underline":false,"type":"primary"},on:{"click":function($event){return _vm.applyPagination('previous')}}})],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Next page","placement":"top-start"}},[_c('el-link',{attrs:{"icon":"el-icon-arrow-right","disabled":_vm.page * _vm.limit >= _vm.total,"underline":false,"type":"primary"},on:{"click":function($event){return _vm.applyPagination('next')}}})],1)],1),_c('br'),_c('div',{staticStyle:{"overflow-y":"hidden"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[(_vm.data && _vm.data.length)?_c('div',{staticClass:"cards"},_vm._l((_vm.data),function(row){return _c('div',{key:row._id,staticClass:"entity-data-card"},[(
                    _vm.getProfilePicture(row) ||
                    _vm.getFieldValue('title', row) ||
                    _vm.getFieldValue('description', row)
                  )?_c('div',[_c('div',{staticClass:"card-profile"},[(_vm.getProfilePicture(row))?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.getProfilePicture(row)}}):_c('div',[(_vm.currentEntity.entity_type == 'INDIVIDUAL')?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":require("@/assets/img/defaultAvatar/Individual.svg"),"alt":"icon"}}):_c('img',{attrs:{"src":require("@/assets/img/defaultAvatar/Business.svg"),"alt":"icon"}})])]),_c('div',{staticClass:"card-body"},[(_vm.checkUrlType() != 'public')?_c('p',{staticClass:"card-title",on:{"click":function($event){return _vm.viewWorkUserData(row)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", row),24, "..."))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", row),24, "..."))+" ")]),_c('p',{staticClass:"card-descripion"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getFieldValue("description", row),140, "..."))+" "),_c('br'),(_vm.getFieldValue('action_button', row))?[_c('action-button-execute',{staticStyle:{"margin-top":"10px"},attrs:{"data":{
                            ..._vm.getFieldValue('action_button', row),
                            ...{ parent_entityDataId: row._id },
                          },"form":_vm.form,"is-view":false,"hideStyles":true}})]:(_vm.checkUrlType() != 'public')?_c('el-link',{staticClass:"card-link",attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.viewWorkUserData(row)}}},[_vm._v(" View details ")]):_vm._e()],2)])]):_c('el-empty',{attrs:{"image-size":100,"description":"Not configured"}})],1)}),0):_c('div',[_c('el-empty',{attrs:{"description":"No data"}})],1)])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }